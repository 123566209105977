import React, { useContext, useState } from 'react';
import { styled } from '@mui/system';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import BottomPanel from '../../components/BottomPanel';
import { TitleLabel, SubTitleLabel } from '../../components/Label';
import Panel from '../../components/Panel';
import { UserContext } from '../../UserContext';
import OrderInfo from '../../components/trade/OrderInfo';
import CloseOutlinedIcon from '@mui/icons-material/Close';
import { navigationBarHeight } from '../../components/Layout';
import NavigationBar from '../../components/NavigationBar';
import { useEffect } from 'react';
import { UserAPI } from '../../apis/UserAPI';
import { OrderAPI } from '../../apis/OrderAPI';

const VerifyButton = styled(Button)(({ theme }) => ({
  color: '#FFF',
  fontSize: '17px',
  fontWeight: 'bold',
  borderRadius: '9px',
}));

export default function TradeDonePage() {
  const { setTabIndex, setLoading } = useContext(UserContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { order } = state;
  const [orderResult, setOrderResult] = useState(order);

  const handleAsset = () => {
    setTabIndex(1);
    navigate('/asset');
  };

  const handleMemberCenter = () => {
    navigate('/changeAccountInfo');
  };

  const handleHomePage = () => {
    setTabIndex(0);
    navigate('/');
  };

  useEffect(() => {
    window.scrollTo(0,0);
    const updateOrderStatus = setTimeout(async () => {
      const response = await OrderAPI.getOrder(orderResult?.id);
      if (response) {
        const invoiceDetail = orderResult?.invoiceDetail;
        const updatedOrder = { ...response, invoiceDetail: invoiceDetail };
        setOrderResult(updatedOrder);
      }
      setLoading(false);
    }, 10000);

    return () => clearTimeout(updateOrderStatus);
  }, []);

  const bottomPanel = () => {
    return (
      <BottomPanel>
        <VerifyButton
          variant="contained"
          fullWidth
          disableElevation
          onClick={handleAsset}>
          確認我的資產
        </VerifyButton>
        <Box width="16px"></Box>
      </BottomPanel>
    );
  };

  const invoiceDetail = () => {
    return (
      <Box
        marginTop="23px"
        marginLeft="16px"
        marginRight="16px"
        marginBottom="90px">
        <Panel sx={{ borderRadius: '12px' }}>
          <Box padding="16px">
            <SubTitleLabel fontSize="15px">
              發票中獎將以簡訊和 Email
              通知，紙本發票將寄送至會員中心的通訊地址。相關資料如下，若需修改請點選下方「
              <span style={{ color: '#FF8F08' }}>修改會員資料</span>」
            </SubTitleLabel>
          </Box>
          <Box marginLeft="16px" display="flex">
            <TitleLabel fontSize="15px" fontWeight="bold">
              手機門號：{orderResult.invoiceDetail.phoneNumber}
            </TitleLabel>
          </Box>
          <Box marginLeft="16px" marginTop="8px" display="flex">
            <TitleLabel fontSize="15px" fontWeight="bold">
              Email：{orderResult.invoiceDetail.email}
            </TitleLabel>
          </Box>
          <Box
            marginLeft="16px"
            marginTop="8px"
            marginBottom="16px"
            display="flex">
            <TitleLabel fontSize="15px" fontWeight="bold">
              地址：{orderResult.invoiceDetail.address}
            </TitleLabel>
          </Box>
        </Panel>
      </Box>
    );
  };

  const bottomPanelWithMemberCenter = () => {
    return (
      <Box display="flex">
        <BottomPanel>
          <VerifyButton
            variant="contained"
            fullWidth
            disableElevation
            onClick={handleMemberCenter}>
            修改會員資料
          </VerifyButton>
          <Box width="20px"></Box>
          <VerifyButton
            variant="contained"
            fullWidth
            disableElevation
            onClick={handleAsset}>
            確認我的資產
          </VerifyButton>
          <Box width="16px"></Box>
        </BottomPanel>
      </Box>
    );
  };

  return (
    <Box sx={{ pt: `${navigationBarHeight}px` }}>
      <NavigationBar
        title="交易結果"
        leftButtonIcon={
          <CloseOutlinedIcon
            style={{ fontSize: 25 }}
            sx={{
              color: (theme) => theme.palette.common.black,
            }}
          />
        }
        leftButtonHandler={handleHomePage}
      />

      <OrderInfo order={orderResult} />

      {orderResult.invoiceDetail === undefined ? (
        <>{bottomPanel() /* 確認我的資產按鈕 */}</>
      ) : (
        <>
          {invoiceDetail()}
          {bottomPanelWithMemberCenter() /* 確認我的資產按鈕 */}
        </>
      )}
    </Box>
  );
}
